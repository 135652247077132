

import * as React from "react"
import Layout from "../components/layout"


const PrinciplesPage = () => {
    return (
        <Layout pageTitle="Principles">

            <h2>Tom Thornley founding principal</h2>

            <p>As a general contractor with deep ties to the region, Tom Thornley has over 40 years of residential and commercial project successes in the Napa and Sonoma Valleys.</p>

            <p>Tom’s excellent reputation and success in the building industry are founded on building great relationships in order to build great buildings. He has excellent, time-tested, long-standing connections with architects, tradesman, subcontractors, city and county officials, and past clients throughout the area and beyond.</p>

            <p>Tom began his career as a carpenter specializing in the restoration of historic buildings.  He became licensed in 1979, and subsequently incorporated new residential and commercial projects into his business. In 1989 Tom co-founded a company that then became the leading commercial builder in the Napa and Sonoma area, and one of the most well regarded and civic-minded companies in the region for over 2 decades.</p>

            <p>Tom founded Thornley Associates in 2014, with a vision to provide general contractor, owner’s rep, and estate management services for a discerning quality-focused clientele.</p>

            <h2>Bruce Crawford</h2>

            <p>Bruce is an executive-level business and project manager with over 25 years of experience on large-scale and multimillion-dollar initiatives. His well-developed, dynamic range of skills and expertise are the result of deep experience and significant leadership roles in real estate development and construction, architectural and interior design, civil engineering and urban planning, and financial planning and management consulting.</p>

            <p>Bruce believes that exceptional outcomes are achieved by exceptional design, and that thorough planning and continual analysis prove common to delivering impeccable and timely excellence in process, production, and result. He brings an outstanding balance of foresight, analytic pragmatism, and executional drive to Thornley Associates projects.</p>

        </Layout>
    )
}

export default PrinciplesPage